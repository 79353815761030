import React from "react"
import { ImageRow } from "./roboto"
import paperimage from "../images/kirigami/kirigami_paper.png"
import plainairfoil from "../images/kirigami/airfoil_plain.png"
import concave_8_norast from "../images/kirigami/concave_8_norastor.png"
import convex_8 from "../images/kirigami/convex_8.png"
import trian_8 from "../images/kirigami/triangle_8.png"
import concave_5 from "../images/kirigami/concave_5.png"
import concave_8 from "../images/kirigami/concave_8.png"
import concave_12 from "../images/kirigami/concave_12.png"
import proto_8 from "../images/kirigami/prototype_8.jpg"
import proto_14 from "../images/kirigami/prototype_14.jpg"
import nostrain from "../images/kirigami/nostrain.png"
import medstrain from "../images/kirigami/medstrain.png"
import highstrain from "../images/kirigami/highstrain.png"
import setup1 from "../images/kirigami/setup1.png"
import setup2 from "../images/kirigami/setup2.jpg"
import setup3 from "../images/kirigami/setup3.jpg"
import concave from "../images/kirigami/concave_airfoil.png"
import convex from "../images/kirigami/convex_airfoil.png"
import triangle from "../images/kirigami/triangle_airfoil.png"

const Airfoil = () => (
  <>
    <h1>Motivation</h1>
    <p>
      Kirigami is a form of cut paper that allows for a flat sheet of material
      to buckle into a 3D surface. Kirigami-like patterns are influenced by and
      evident in nature, such as on the scales of fish and reptiles. The 3D
      surface of a deformed kirigami can be potentially utilized to achieve a
      desired lift and drag on airfoils and thus be embedded onto a wing of a
      drone or small airplane. <br />
      <br />I worked on this project with Brian Januzzi, Wyatt Giroux and Dr.
      Sahab Babaee, under the mentorship of Prof. Giovanni Traverso, and it was
      supported by Lincoln Laboratories.
      <br />
      <br />
      Dr. Sahab Babaee has explored the characteristics of individual unit cells
      of a kirigami design for a flat sheet, including strain deformations (a)
      and the popping angle theta (b) of the deformed shape. Individual
      characteristics of a kirigami sheet wrapped around an airfoil rather than
      a flat sheet would be slightly different and needs to be explored.
    </p>

    <ImageRow
      images={[
        {
          image: paperimage,
          captionSize: 0.4,
          caption:
            "Babaee, S., Pajovic, S., Rafsanjani, A., Shi, Y., Bertoldi, K., and Traverso, G., 2020, “Bioinspired Kirigami Metasurfaces as Assistive Shoe Grips,” Nat Biomed Eng, 4(8), pp. 778–786.",
        },
      ]}
      height="13"
    />

    <h1>Prototypes</h1>
    <p>
      I designed a kirigami sheet which would wrap around a NACA 0015 airfoil
      (shown below). The sheet would be laser cut out of .003" thick plastic.
    </p>
    <ImageRow images={[plainairfoil]} height="13" />

    <p>
      The kirigami sheets required consistent sizing across different prototypes
      and I created prototypes for 3 different kirigami shapes (concave,
      triangle, convex). The top edge would be aligned with the upper tip of the
      airfoil and the sheet would be wrapped around the airfoil and pulled to
      achieve a specified % strain. I began with creating designs for a
      specified deformation (left) but later included all % strains on each
      prototype for a more streamlined process.
    </p>
    <ImageRow
      images={[
        { image: concave_8_norast, caption: "concave" },
        { image: trian_8, caption: "triangular" },
        { image: convex_8, caption: "convex" },
      ]}
      height="13"
    />
    <p>
      I also explored different number of needles (pattern unit) per row for
      each shape and added rastor lines (not fully cut through using laser
      cutter) so each needle would pop up more easily.
    </p>
    <ImageRow
      images={[
        { image: concave_5, caption: "5 per row" },
        { image: concave_8, caption: "8 per row" },
        { image: concave_12, caption: "12 per row" },
      ]}
      height="13"
    />
    <p>
      I experimented with several methods of gluing and attachment methods for
      connecting the sheet to the foam airfoil. The best method was using Super
      Glue and aligning the sheet at the marked edge.
    </p>
    <ImageRow images={[proto_8, proto_14]} height="13" />
    <p>
      The different % strains were evident in the different prototypes. A higher
      % strain caused the needles to pop to a higher popping angle.
    </p>
    <ImageRow
      images={[
        { image: nostrain, caption: "0% Strain" },
        { image: medstrain, caption: "5% Strain" },
        { image: highstrain, caption: "10% Strain" },
      ]}
      height="9"
    />
    <p>
      The prototypes were placed in front of a wind tunnel and tested with
      different wind speeds to get lift and drag values.
    </p>
    <ImageRow images={[setup1, setup2, setup3]} height="13" />
    <h1>Finite Element Analysis</h1>
    <p>
      With the guidance of Dr. Babaee, I analyzed the deformation of the
      kirigami using simulations. I first used SolidWorks to model the
      prototype, wrapping the kirigami sheet design around a solid airfoil.
    </p>
    <ImageRow
      images={[
        { image: convex, caption: "concave" },
        { image: triangle, caption: "triangle" },
        { image: concave, caption: "convex" },
      ]}
      height="9"
    />
    <p>
      Using Abaqus, I set up a simulation for the kirigami sheet being stretched
      to a desired strain (up to 20%).
    </p>
    <div style={{ display: "flex", justifyContent: "center" }}>
      <iframe
        width="50%"
        height="220"
        style={{ borderRadius: "5px" }}
        src="https://www.youtube.com/embed/irCzs5GmpWU"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
    <p>
      <br />
      Different kirigami shapes as well as needles per row were simulated. Using
      simulation results, I calculated the average popping angle for inner
      needles to compare to physical prototypes.
    </p>
  </>
)

export default Airfoil
