import React from "react"
import { ImageRow } from "./roboto"

const AutoBrailler = () => (
  <>
    <div style={{ display: "flex", justifyContent: "center" }}>
      <iframe
        width="325"
        height="200"
        src="https://www.youtube.com/embed/oK6Eukieak8"
        frameBorder="0"
        style={{ borderRadius: "5px" }}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
    <p>
      <br />
      For ATHack 2020, MIT's Assistive Technology Hackathon, I and a couple
      friends created an Automated Brailler. Given a Perkin's Brailler (which
      works similarly to a typewriter), we built a mechanical extension that
      allows text to be printed in braille. The prototype was a prize winner at
      the hackathon. More information about the project can be found{" "}
      <a
        href="https://devpost.com/software/autobrailler"
        style={{ color: "#0000EE" }}
      >
        here.
      </a>
    </p>
    <div style={{ display: "flex", justifyContent: "center" }}>
      <iframe
        frameBorder="0"
        scrolling="no"
        width="500"
        height="600"
        src="https://drive.google.com/file/d/1ORaBYAV5LfhqZQSRsiopcrKWBxUuUovz/preview"
      ></iframe>
    </div>
  </>
)

export default AutoBrailler
