import React from "react"

import calculations from "../images/robotopics/calculations.png"
import path from "../images/robotopics/path.png"
import init_design1 from "../images/robotopics/init_design1.png"
import init_design2 from "../images/robotopics/init_design2.png"
import init_design3 from "../images/robotopics/init_design3.png"
import new_chassis1 from "../images/robotopics/new_chassis.png"
import new_chassis2 from "../images/robotopics/new_chassis2.png"
import new_robot1 from "../images/robotopics/final_robot1.png"
import new_robot2 from "../images/robotopics/final_robot2.png"
import new_robot3 from "../images/robotopics/final_robot3.png"
import competition1 from "../images/robotopics/competition1.jpg"
import competition2 from "../images/robotopics/competition2.jpg"
import competition3 from "../images/robotopics/competition3.jpg"
import init_cad1 from "../images/robotopics/init_cad1.jpg"
import init_cad2 from "../images/robotopics/init_cad2.png"

export const ImageRow = ({ images, height, nopadding }) => (
  <div
    style={{
      margin: nopadding ? "0" : "2rem 0",
      display: "flex",
      justifyContent: "space-evenly",
      flexWrap: "wrap",
    }}
  >
    {images.map((image, i) => {
      if (image.image) {
        const imageStyles = {
          borderRadius: "5px",
          margin: "0.25rem",
        }
        if (image.tooWideOnMobile && window.innerWidth < 600) {
          imageStyles.width = "100%"
        } else {
          imageStyles.height = `${height}rem`
        }
        return (
          <div
            key={i}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img src={image.image} style={imageStyles} />
            {image.caption && (
              <p
                style={{
                  fontSize: `${image.captionSize || 1}em`,
                  textAlign: "center",
                  width: "13rem",
                }}
              >
                {image.caption}
              </p>
            )}
          </div>
        )
      } else {
        return (
          <img
            key={i}
            src={image}
            style={{
              height: `${height}rem`,
              borderRadius: "5px",
              margin: "0.25rem",
            }}
          />
        )
      }
    })}
  </div>
)

const Roboto = () => (
  <>
    <p>
      For Design and Manufacturing I (2.007), I designed and fabricated a
      remote-controlled robot from scratch. The ultimate goal was for the robot
      to participate in a robot competition, which consisted of a game board on
      which the robot can move up and down 2 ramps, place flags, turn handles
      and pick up "moon rocks" of variable sizes for a designated amount of
      points. The game board was moon themed for Apollo's Moon Landing's 50
      anniversary. The following arrows show the route my robot will take.
    </p>
    <ImageRow images={[path]} height="14" />
    <p>
      I decided to have my robot move down the 2 ramps and pick up moon rocks,
      which required calculations on "tip vs. slip" for slopes (left) and
      reiterations of designing to ensure the center of mass was well located. I
      also sketched and modelled concepts for the shovel attachment (middle,
      right).
    </p>
    <ImageRow images={[calculations, init_cad1, init_cad2]} height="12" />
    <p>
      I decided to use a timing pulley mechanism which is attached to a motor in
      order to control the angle of the shovel. My initial prototype of the
      mechanism looked like this:
    </p>
    <ImageRow images={[init_design1, init_design2]} height="12" />
    <p>
      After ensuring that the motor controls the shovel effectively, I modelled
      the mechanism integrated with the chassis on SolidWorks (left). To ensure
      the robot was lighter to prevent slipping, I designed a new chassis to be
      waterjetted (middle). Integrated with new custom acrylic wheels (right),
      my robot was close to being complete.
    </p>

    <ImageRow images={[init_design3, new_chassis1, new_chassis2]} height="12" />

    <p>
      Through testing, I realized that shovel was too heavy and the smaller
      rocks put onto the chassis were slipping. I redesigned the shovel with
      more cuts in the middle and added wires/string to the chassis to address
      the problems. My final robot, "Roboto Avocado" is shown below.
    </p>
    <ImageRow images={[new_robot1, new_robot2, new_robot3]} height="14" />
    <p>
      In the robot competition, I qualified for the Top 32 out of over 100
      students and competed in the final competition. In the final competition,
      Roboto Avocado successfully moved down and up 2 ramps with different
      coefficients of friction and carried up 4 irregular-shaped rocks.
    </p>
    <ImageRow images={[competition1, competition2, competition3]} height="14" />
  </>
)
export default Roboto
