import React from "react"
import { ImageRow } from "../projects/roboto"
import jimmy2 from "../images/jimmy2.jpg"
import jimmy3 from "../images/jimmy3.png"
import flashlight from "../images/flashlight.png"

const Firstprojects = () => (
  <>
    <h1>4-foot model rocket “Jimmy”</h1>
    <p>
      Fabrication and launch of model rocket
      <br />
      Received Level 1 High-Power Rocketry Certification from National
      Association of Rocketry
    </p>
    <ImageRow
      images={[jimmy2, { image: jimmy3, tooWideOnMobile: true }]}
      height="7"
    />

    <div style={{ display: "flex", justifyContent: "center" }}>
      <iframe
        title="Rocket video"
        width="180"
        height="250"
        style={{ borderRadius: "5px" }}
        src="https://www.youtube.com/embed/u_xrmZ1FYtM"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>

    <h1>Metal Flashlight</h1>
    <p>
      2.670 (Mechanical Engineering Tools) <br /> Knurled and finished | Used
      mill and lathe
    </p>
    <ImageRow images={[flashlight]} height="12" />
  </>
)

export default Firstprojects
