import React from "react"
import bionicankle from "../images/biomech2018/carney.png"
import dyno1 from "../images/biomech2018/dyno1.png"
import dyno2 from "../images/biomech2018/dyno2.png"
import dyno3 from "../images/biomech2018/dyno3.png"
import case1 from "../images/biomech2018/case1.jpg"
import case2 from "../images/biomech2018/case2.png"
import case3 from "../images/biomech2018/case3.png"
import bypass1 from "../images/biomech2018/bypass1.png"
import bypass2 from "../images/biomech2018/bypass2.png"
import bypass3 from "../images/biomech2018/bypass3.png"

import { ImageRow } from "./roboto"

const Biomech2018 = () => (
  <>
    <p>
      I worked in MIT Media Lab's Biomechatronics Group, generally assisting in
      mechatronics projects involving robotic prostheses. My initial work in the
      lab involved developing electronic interfaces for the prostheses by
      creating wire harnesses via wire splicing and soldering. I also ported and
      adapted new features and calibration tools for the lab’s code base for
      FlexSEA (wearable robotics electronics architecture which includes an
      embedded computer, microcontroller and motion controller) from Dephy, Inc.
      The powered prosthesis developed by Matt Carney, along with other graduate
      students in the lab, is shown below.
    </p>
    <ImageRow
      images={[
        {
          image: bionicankle,
          captionSize: 0.4,
          caption:
            "Carney, M., Shu, T., Stolyarov, R., Duval, J.-F., and Herr, H., 2019, Design and Preliminary Results of a Reaction Force Series Elastic Actuator for Bionic Ankle Prostheses, engrXiv.",
        },
      ]}
      height="13"
    />

    <h1>ODROID Case</h1>
    <p>
      I used SolidWorks to CAD a protective case for an ODROID single-board
      computer that can be attached to a sensor-driven prosthesis. The case
      involved a front and back component and the ODROID could be secured within
      the casing using screws at each corner.
    </p>
    <ImageRow images={[case1, case2, case3]} height="10" />

    <h1>Bypass for Transfemoral Prosthesis</h1>
    <p>
      I altered an iWalk (hands-free crutch) to allow non-amputees to wear and
      test a transfemoral prothesis. Adjustments made include:{" "}
      <ul>
        <li>
          Modeling and 3D-printing a connector to the prosthesis, which could be
          secured with a nut and bolt.
          <li>Re-sewing parts of the straps to make them tighter.</li>
        </li>
      </ul>
    </p>
    <ImageRow
      images={[
        { image: bypass1, caption: "Existing hands-free crutch (iWalk)" },
        { image: bypass2, caption: "Complete bypass" },
        { image: bypass3, caption: "Connector between iWalk and robot" },
      ]}
      height="14"
    />
    <h1>Dynamometer Testing</h1>
    <p>
      I aimed to automate motor characterization and generate an efficiency map
      (torque vs. rotational speed diagram) for the motors. The test bench for
      the project consisted of 2 brushless DC motors (test and load motor) and a
      torque meter. Each motor had an encoder and was connected to a FlexSEA and
      a power source. My individual tasks included:
      <ul>
        <li>Using mill to fabricate metal parts for test bench</li>
        <li>Managing wiring between microcontrollers and motors</li>
        <li>Setting up communication between motors and microcontrollers </li>
        <li>
          Programming motors to increase in speed and current based on set time
          increments
        </li>
        <li>
          Writing data to Excel and MATLAB to generate efficiency graphs and
          syncing torque meter values with flexSEA data
        </li>
      </ul>
      <p>
        Unfortunately the project was not brought to completion during my time
        in the lab due to a faulty encoder.
      </p>
    </p>
    <ImageRow
      images={[
        { image: dyno1, caption: "Milled motor holder" },
        {
          image: dyno3,
          caption:
            "Basic components of test bench: motor holders, motors, torque meter",
        },
        { image: dyno2, caption: "Complete set-up" },
      ]}
      height="14"
    />
  </>
)

export default Biomech2018
