import React from "react"
import { ImageRow } from "./roboto"
import plane from "../images/plane.png"

const Boeing = () => (
  <>
    <ImageRow images={[plane]} height="13" />

    <p>
      Over Summer 2020, I interned virtually at Boeing Commercial Airplanes with
      the 777x Primary Flight Controls team. My main tasks included:
      <ul>
        <li>
          Developing tests to verify linear modeling software with flight
          simulation data by performing frequency sweeps of signals
        </li>
        <li>
          Analyzing flight test data using MATLAB, examining FFT of signals, and
          facilitating discussions regarding any discrepancies
        </li>
      </ul>
    </p>

    <p>
      <br />
      Through the internship, I learned a lot about flight dynamics and
      controls. More information about the 777x plane can be found{" "}
      <a
        href="https://www.boeing.com/commercial/777x/"
        style={{ color: "#0000EE" }}
      >
        here.
      </a>
    </p>
  </>
)

export default Boeing
