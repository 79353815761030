import React from "react"

import lizardPoster from "../images/lizard_leg_poster.png"

const Lizardrobot = () => (
  <>
    <p>
      As a team of 4 students, we designed a 2 DOF robotic leg capable of 360
      degree motion, implemented impedance control for the leg, and tested its
      movement in sand (mimicking the motion of shovel-snouted lizards in the
      desert). Here is the final poster for 2.74 (Bio-Inspired Robotics class):
    </p>

    <img src={lizardPoster} alt="Lizard Poster" style={{ width: "100%" }} />
  </>
)

export default Lizardrobot
