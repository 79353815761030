import React from "react"
import { ImageRow } from "./roboto"

import final_baseplate from "../images/Sensus/final_baseplate.png"
import final_filament from "../images/Sensus/final_filament.png"
import final_filament2 from "../images/Sensus/final_filament2.png"
import final_product from "../images/Sensus/final_product.png"
import final_product1 from "../images/Sensus/final_product1.png"
import assemblyreview1 from "../images/Sensus/assemblyreview_1.png"
import assemblyreview2 from "../images/Sensus/assemblyreview_2.png"
import casing_final from "../images/Sensus/casing_final.jpg"
import casing_final2 from "../images/Sensus/casing_final2.jpg"
import footmodel from "../images/Sensus/footmodel.png"
import product_sheet from "../images/Sensus/product_sheet.png"
import techreview from "../images/Sensus/techreview_pic.jpg"
import techreview2 from "../images/Sensus/techreview_pic2.jpg"

const Sensus = () => (
  <>
    <p>
      I designed and built a product from scratch with a team of 17 mechanical
      engineering students for our senior capstone (2.s009)! In a span of
      roughly two months, we ideated to find gaps in the market for a product
      idea, selected an idea, and designed, iterated, and tested the product.
      <br /> <br />
      The product - Sensus - is an at-home screening device for diabetics at
      risk of Peripheral Neuropathy, where symptoms include numbness and losing
      sensation in the foot. Sensus aims to poke various locations of a person's
      foot, and an app asks the user whether they felt the stimulus on their
      feet. Throughout the project, we faced not only technical challenges but
      also focused on having a thoughtful UI so the product is desirable,
      aesthetic, and useful for our users.
      <br />
      <br />
      Our team even won the MIT Mechanical Engineering Thomas Sheridan Award for
      our work. Here is the final product:
    </p>

    <ImageRow
      images={[{ image: final_product }, { image: final_product1 }]}
      height="16"
    />
    <p>
      Filament Module: The main internal mechanism to apply force to the feet
      consists of a crank rocker mechanism and a spring that compresses when in
      contact:
    </p>
    <ImageRow images={[final_filament, final_filament2]} height="13" />

    <p>
      A baseplate holds all of the filament modules and is connected to the
      outer casing. It has many holes so the filament modules can be connected
      to other locations, accommodating a large range of foot sizes with a
      simple manufacturing process.
    </p>
    <ImageRow images={[final_baseplate]} height="14" />

    <p>
      The outer casing was thermoformed and the mold was CNC milled using green
      foam. Customizable foot inserts are separately thermoformed to accommodate
      varying foot sizes and shapes while the main base mold would be used for
      any foot size.
    </p>
    <ImageRow
      images={[
        { image: casing_final, caption: "Base Mold" },
        { image: casing_final2, caption: "Customizable Foot Inserts" },
      ]}
      height="12"
    />
    <p>
      The user interacts with the physical product by aligning their feet on the
      foot inserts and sensing (or not sensing) a poke by the filament module:
    </p>
    <ImageRow images={[footmodel]} height="11" />
    <h1>Previous Iterations</h1>
    <p>
      An initial design consisted of a rack and pinion for z-actuation and a
      gantry-style design.
    </p>
    <ImageRow
      images={[
        { image: assemblyreview1, caption: "Z Stage" },
        { image: assemblyreview2, caption: "XY Stage" },
      ]}
      height="20"
    />
    <p>
      An earlier prototype of the design included the XY gantry system using a
      belt drive, a crank rocker for the filament module, and a metal grid for
      user foot placement. With a constraint of time (just 2 weeks), the XY
      stage lacked precision and had backlash, and the reliability necessary for
      a medical product made this solution unfeasible.
    </p>
    <ImageRow
      images={[{ image: techreview }, { image: techreview2 }]}
      height="15"
      ß
    />
    <p>
      Even with time constraints and Covid-19 restrictions, we managed to design
      a product we were proud of! We even interviewed and tested with potential
      users.{" "}
    </p>
    <ImageRow images={[product_sheet]} height="32" />

    <p>
      During this project, I focused on conceptual design/CAD, led the casing
      subteam, and worked on mechanism design/assembly. I also served as
      financial officer, keeping track of thousands of dollars of part orders.
    </p>
  </>
)

export default Sensus
