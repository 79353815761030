import React from "react"

import extractedData from "../images/jellyfishpics/journaldatapoints.png"
import matlab_config from "../images/jellyfishpics/expectedMATLAB.png"
import solidworksMold1 from "../images/jellyfishpics/solidworksMold1.png"
import solidworksMold2 from "../images/jellyfishpics/solidworksMold2.png"
import mold_3dprinted from "../images/jellyfishpics/mold_3dprinted.png"
import siliconCasting1 from "../images/jellyfishpics/siliconCasting1.png"
import siliconCasting2 from "../images/jellyfishpics/siliconCasting2.png"
import fabricatedActuator from "../images/jellyfishpics/fabricatedActuator.png"
import dic_actuator from "../images/jellyfishpics/dic_actuator.png"
import conceptDesign from "../images/jellyfishpics/conceptDesign_jellyfish.png"
import legs4 from "../images/jellyfishpics/legs_4.png"
import completedPrototype from "../images/jellyfishpics/completed_prototype.png"
import { ImageRow } from "./roboto"

const LabelledImage = ({ text, children }) => {
  const containerStyle = {
    display: "flex",
    alignItems: "center",
    paddingBottom: "2rem",
  }
  const textStyle = { width: "20%", padding: "0 1rem" }
  if (window.innerWidth < 550) {
    containerStyle.flexDirection = "column"
    textStyle.width = "70%"
    textStyle.textAlign = "center"
    textStyle.paddingBottom = "1rem"
  }

  return (
    <div style={containerStyle}>
      <div style={textStyle}>{text}</div>
      <div
        style={{
          width: "80%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {children}
      </div>
    </div>
  )
}

const Jellyfish = () => (
  <>
    <p>
      Working with PhD candidate Lucy Hu in Prof. Ellen Roche's Therapeutic
      Technology Design and Development Lab, I developed a soft robotic
      jellyfish prototype. With this project, I was able to contribute to the
      journal article, "Pre-curved, fiber-reinforced actuators enable
      pneumatically efficient replication of complex biological motions." The
      jellyfish prototype uses novel pre-curved soft robotic fiber-reinforced
      actuators which can bend, twist and straighten to desired configurations
      based on specified air pressure inputs and geometric design parameters.
      <br />
      <br />
    </p>

    <LabelledImage text="Extracted motion trajectory data">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          width: "13rem",
        }}
      >
        <img
          src={extractedData}
          style={{ height: "13rem", borderRadius: "5px" }}
        />
        <p style={{ fontSize: "0.4em", textAlign: "center" }}>
          Cyanea capillata Bell Kinematics Analysis through Corrected In Situ
          Imaging and Modeling Using Strategic Discretization Technique” Alex A.
          Villanueva, Shashank Priya
        </p>
      </div>
    </LabelledImage>
    <LabelledImage text="MATLAB-generated expected configuration">
      <div style={{ width: window.innerWidth > 550 ? "70%" : "100%" }}>
        <img
          src={matlab_config}
          style={{ width: "100%", borderRadius: "5px" }}
        />
      </div>
    </LabelledImage>
    <LabelledImage text="Mold on SolidWorks">
      <div style={{ height: "17rem", marginRight: "2rem" }}>
        <img
          src={solidworksMold1}
          style={{ height: "100%", borderRadius: "5px" }}
        />
      </div>
      <div style={{ height: "17rem" }}>
        <img
          src={solidworksMold2}
          style={{ height: "100%", borderRadius: "5px" }}
        />
      </div>
    </LabelledImage>
    <LabelledImage text="3D-printed mold">
      <div style={{ height: "17rem" }}>
        <img
          src={mold_3dprinted}
          style={{ height: "100%", borderRadius: "5px" }}
        />
      </div>
    </LabelledImage>
    <LabelledImage text="Silicone casting">
      <ImageRow
        images={[siliconCasting1, siliconCasting2]}
        height="10"
        nopadding
      />
    </LabelledImage>
    <LabelledImage text="Fabricated actuator">
      <ImageRow images={[fabricatedActuator]} height="14" nopadding />
    </LabelledImage>
    <LabelledImage text="Digital image correlation">
      <ImageRow
        images={[{ image: dic_actuator, tooWideOnMobile: true }]}
        height="10"
        nopadding
      />
    </LabelledImage>
    <LabelledImage text="Concept design of integrated actuators for jellyfish">
      <ImageRow
        images={[{ image: conceptDesign, tooWideOnMobile: true }]}
        height="10"
        nopadding
      />
    </LabelledImage>
    <LabelledImage text="Fabricated parts: actuators and connector">
      <ImageRow images={[legs4]} height="15" nopadding />
    </LabelledImage>
    <LabelledImage text="Completed prototype of jellyfish">
      <ImageRow images={[completedPrototype]} height="12" nopadding />
    </LabelledImage>
    <LabelledImage text="Bioluminescent silicone prototype in water">
      <iframe
        width={window.innerWidth > 550 ? "60%" : "100%"}
        height="250"
        style={{ borderRadius: "5px" }}
        src="https://www.youtube.com/embed/4uH_CXsHCQI"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </LabelledImage>
  </>
)

export default Jellyfish
