import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Project from "../components/project"
import Jellyfish from "../projects/jellyfish"
import Yoyoproject from "../projects/yoyoproject"
import Piano_measurement from "../projects/piano_measurement"
import Roboto from "../projects/roboto"
import Toydesign from "../projects/toy_design"
import Firstprojects from "../projects/firstprojects"
import AutoBrailler from "../projects/autobrailler"
import Airfoil from "../projects/airfoil"
import Boeing from "../projects/boeing"
import DLR from "../projects/dlr"
import Biomech2018 from "../projects/biomech2018"
import Lizardrobot from "../projects/lizardrobot"
import Sensus from "../projects/sensus"
import Prosthesis from "../projects/prosthesis"

import styles from "../styles/portfolio.module.css"
import final_jellyfish from "../images/jellyfishpics/jellyfish_final.jpg"
import roboto_final from "../images/final_roboto.jpg"
import sound_freq from "../images/sound_freq.png"
import yoyo_pic from "../images/yoyo_pic.jpg"
import room_design1 from "../images/room_design1.png"
import jimmy from "../images/rocket_jimmy.jpg"
import brailler from "../images/brailler_final.jpg"
import kirigami from "../images/kirigami/kirigami_airfoil.jpg"
import plane from "../images/plane.png"
import dlr_setup from "../images/dlr/front_supplyunit.jpg"
import dyno1 from "../images/biomech2018/dyno2_square.png"
import lizardleg from "../images/lizard_leg.png"
import sensus_pic from "../images/Sensus/final_product.png"
import foottest from "../images/prosthesis_setup.jpg"

const PortfolioPage = () => (
  <Layout>
    <SEO title="Portfolio" />
    <div className={styles.container}>
      <Project
        picture={foottest}
        name="Series-Elastic Spring for a Powered Prosthesis"
      >
        <Prosthesis />
      </Project>

      <Project picture={sensus_pic} name="Sensus">
        <Sensus />
      </Project>

      <Project picture={final_jellyfish} name="Soft Robotic Jellyfish">
        <Jellyfish />
      </Project>

      <Project picture={kirigami} name="Kirigami Airfoils">
        <Airfoil />
      </Project>

      <Project
        picture={lizardleg}
        name="Robot Simulating Lizard Leg Movement in Sand"
      >
        <Lizardrobot />
      </Project>

      <Project
        picture={roboto_final}
        name="Remote-Controlled Rock-Scooping Robot"
      >
        <Roboto />
      </Project>

      <Project picture={dlr_setup} name="Test Bench for Carbon Fiber Tows">
        <DLR />
      </Project>

      <Project
        picture={sound_freq}
        name="Investigation of Grand Piano Lid Angle vs. Piano Timbre"
      >
        <Piano_measurement />
      </Project>

      <Project picture={yoyo_pic} name="Self-Manufactured Sushi Yoyos">
        <Yoyoproject />
      </Project>

      <Project picture={dyno1} name="Biomechatronics Group, 2018">
        <Biomech2018 />
      </Project>

      <Project picture={room_design1} name="Design for Entertainment">
        <Toydesign />
      </Project>

      <Project picture={brailler} name="AutoBrailler">
        <AutoBrailler />
      </Project>

      <Project picture={jimmy} name="First Creations">
        <Firstprojects />
      </Project>

      <Project picture={plane} name="Boeing Internship">
        <Boeing />
      </Project>
    </div>
  </Layout>
)

export default PortfolioPage
