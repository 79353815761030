import React from "react"
import { ImageRow } from "../projects/roboto"

import pitch1 from "../images/boxfort/pitch1.jpg"
import pitch2 from "../images/boxfort/pitch2.jpg"
import pitch3 from "../images/boxfort/pitch3.jpg"
import pitch4 from "../images/boxfort/pitch4.jpg"
import pitch5 from "../images/boxfort/pitch5.jpg"

import spinning_toy from "../images/toys/spinning_toy.png"
import duck_toy from "../images/toys/duck_toy.png"
import sketchmodel1 from "../images/toys/sketchmodel1.jpg"
import sketchmodel2 from "../images/toys/sketchmodel2.jpg"
import mechanism_toy from "../images/toys/Final_toy3.jpg"
import final_toy1 from "../images/toys/Final_toy1.jpg"
import final_toy2 from "../images/toys/Final_toy2.jpg"
import final_toy3 from "../images/toys/mat.jpg"
import roomdesign1 from "../images/boxfort/roomdesign_stack.png"
import roomdesign2 from "../images/room_design1.png"
import roomdesign3 from "../images/boxfort/roomdesign_whichweight.png"

const Toydesign = () => (
  <>
    <h1>Box Fort Externship</h1>
    <p>
      I helped create interactive entertainment experiences. As part of the
      ideation phase, I helped pitch many concepts for games. A couple of the
      sketched concepts are shown here:
    </p>
    <ImageRow images={[pitch1, pitch2, pitch3, pitch4, pitch5]} height="15" />
    <p>
      Using SketchUp, I brought some concepts to a 3D space:
      <li>
        8-Bit Build: The player builds up the image seen on a screen using large
        cube blocks. The player can either build it up on a flat plane (left) or
        vertically (middle).{" "}
      </li>
      <li>
        Which Weight: Given objects of varying shapes and sizes, the player
        tries to place the objects in order of increasing weight.
      </li>
    </p>
    <ImageRow images={[roomdesign2, roomdesign1, roomdesign3]} height="12" />
    <p>
      Other tasks I had included preparing for and hosting play-tests, designing
      and testing sketch models, and creating mounting solutions for individual
      entertainment spaces.
    </p>

    <h1>Toy Product Design (2.00B)</h1>
    <p>
      <a href="http://web.mit.edu/2.00b/www/" style={{ color: "#0000EE" }}>
        2.00B
      </a>{" "}
      is a MIT freshman class dedicated to toy design. I took the class in 2018
      and was a lab assistant for the class in 2020. In 2018, my first toy I
      built was a duck pull toy:
    </p>
    <ImageRow images={[duck_toy]} height="10" />
    <p>
      The main project I worked on with 5 other students was the creation of an
      astronaut and alien-themed spinning toy with a target point system. My
      role in the team was designing and building mechanical components. Some
      features of the toy include:
      <li>Collapsibility for transporting the toy</li>
      <li>
        Randomized spinning direction and speed of central cylinder for unique
        target-hitting experience
      </li>
      <li>
        Themed design for main spinning body of toy, the mat showing where
        players stand to play, and bean bags for players to throw.
      </li>
    </p>
    <ImageRow images={[spinning_toy]} height="15" />
    <p>
      The design process involved brainstorming ideas, selecting toys using Pugh
      charts and creating sketch models which were tested at the Boston
      Children's Museum to determine playability. The "Plays Like" sketch model
      (left) was created using a Lazy Susan and tested how fun the idea of
      throwing items at moving targets is. It turned out to be a huge success!
      The "Looks Like" sketch model (right) displayed an initial concept of how
      the toy would look like - fitting the theme of "dream" with many sheep.
    </p>
    <ImageRow images={[sketchmodel1, sketchmodel2]} height="15" />
    <p>
      After several iterations of mechanical fabrication to get different
      compartments of the toy to spin, we decided to make the inner main disc of
      the toy spin using an Arduino, allowing for the attached outer cylinder to
      spin with it.
    </p>
    <ImageRow images={[mechanism_toy]} height="12" />
    <p>
      The final toy could be collapsed so that longer components such as the
      legs could be stored in the outer cylinder (left). The bean bags (middle)
      and mat (right) were astronaut vs. alien-themed to bring the excitement of
      space exploration to the players.
    </p>
    <ImageRow images={[final_toy1, final_toy2, final_toy3]} height="12" />
  </>
)

export default Toydesign
