import React from "react"

const Prosthesis = () => (
  <>
    <p>
      For my Undergraduate Thesis, under the mentorship of Prof. Hugh Herr and
      PhD candidate Lucy Du, I developed a preliminary design for a nonlinear
      stiffening spring, which acts as the elastic element (series elastic
      actuator) of a powered transtibial prosthesis with cycloidal drive
      transmission. I characterized its mechanical response by creating a
      mathematical model for angular deflection of a spring wrapping around a
      cylindrical surface and simulating its compressive behavior using
      finite-element analysis. I fabricated the spring by water-jetting nylon
      and designed and built an experimental setup to test the angular
      deflection of the spring using an Instron machine. I processed the
      experimental results, comparing them to the simulation results to validate
      the proposed model.
      <br />
      <br />
      Here is the thesis:
    </p>

    <div style={{ display: "flex", justifyContent: "center" }}>
      <iframe
        title="undergrad_thesis"
        frameBorder="0"
        scrolling="no"
        width="640"
        height="825"
        src="https://drive.google.com/file/d/1rt9_TJ3TTUfO0WGuU6IN_h757uJ0Tefd/preview"
      ></iframe>
    </div>
  </>
)

export default Prosthesis
