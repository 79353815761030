import React from "react"
import total_setup from "../images/dlr/total_unit.jpg"
import cad_material from "../images/dlr/cad_materialunit.png"
import material_unit1 from "../images/dlr/material_unit1.jpg"
import material_unit2 from "../images/dlr/material_unit2.jpg"
import fil_cad from "../images/dlr/cad_winder.png"
import drawing from "../images/dlr/winder_dims.png"
import winder1 from "../images/dlr/winder1.jpg"
import winder2 from "../images/dlr/winder2.jpg"

import { ImageRow } from "../projects/roboto"

const DLR = () => (
  <>
    <p>
      During my internship at DLR (German Aerospace Center) in Stade, Germany, I
      mainly focused on rapid prototyping for setups involving carbon fiber
      tows. I communicated mostly in German, which was challenging since I had
      to become experienced in using technical terms.
      <br />
      <br />
      Carbon fiber tows are used in airplane manufacturing, such as for airplane
      frames and wings. The DLR Center for Lightweight Production Technology
      experiments with different carbon fiber manufacturing processes, and my
      goal was to create a test bench for supplying carbon tows from a filament
      roller and winding them across the experimental space.
    </p>

    <ImageRow
      images={[{ image: total_setup, caption: "Complete Set-Up" }]}
      height="17"
    />

    <p>
      The left component is the material supply unit and the right component is
      the filament winder. My tasks for this project included:
      <ul>
        <li>Conceptualized designs through sketches and testing</li>
        <li>
          Used SolidWorks to create custom parts and integrate all parts in
          Assembly
        </li>
        <li>
          Researched and ordered parts based on specifications and cost
          restraints
        </li>
        <li>
          Generated engineering drawings and contacted local machining companies
          to machine custom parts
        </li>
        <li>Assembled prototype</li>
      </ul>
    </p>

    <h1>Material Supply Unit</h1>
    <ImageRow images={[{ image: cad_material }]} height="22" />

    <p>
      Mechanisms include:
      <ul>
        <li>Motor turns filament roller</li>
        <li>Tow moves along a path of rollers that have adjustable heights</li>
        <li>Green milled block connects...</li>
        <ul>
          <li>
            Linear displacement sensor, which measures downward displacement of
            connected roller
          </li>
          <li>
            Linear guide, allowing connected roller to move more smoothly
            vertically
          </li>
          <li>
            Weights (steel plates), which can be manually altered in amount and
            maintain tension of tow
          </li>
        </ul>
      </ul>
    </p>
    <ImageRow
      images={[
        { image: material_unit1, caption: "Rollers with adjustable heights" },
        {
          image: material_unit2,
          caption: "Connection between sensor and roller",
        },
      ]}
      height="13"
    />
    <h1>Filament Winder</h1>
    <ImageRow images={[{ image: fil_cad }]} height="16" />
    <p>
      Mechanisms include:{" "}
      <ul>
        <li>Motor turns winding tube</li>
        <li>
          Pneumatic cylinder allows rubber roller (with adjustable height and
          distance) to apply force on winding tube, pulling tow upwards
        </li>
        <li>Hydraulic bushing allows winding tube to be easily replaceable</li>
      </ul>
      The winding tube had to be customizable and milled at a local machine
      shop.
    </p>
    <ImageRow
      images={[{ image: drawing, caption: "Winding tube engineering drawing" }]}
      height="13"
    />
    <p>
      The prototype also utilized aluminum profiles which can be adjusted to
      change winding tube and pneumatic cylinder placement.
    </p>
    <ImageRow
      images={[
        { image: winder2, caption: "Front view" },
        { image: winder1, caption: "Top view" },
      ]}
      height="15"
    />
  </>
)

export default DLR
