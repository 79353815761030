import React from "react"

import final_poster from "../images/final_poster_2671.png"

const Piano_measurement = () => (
  <>
    <p>
      For 2.671 (Measurement and Instrumentation class), I analyzed the
      relationship between grand piano lid angle and piano timbre, which
      combines my interests as a pianist and mechanical engineer. I applied a
      controlled amount of force to piano keys and used power spectral density
      (PSD) analysis to analyze the piano sound's harmonics/overtones. Here is
      the poster and paper detailing my work:{" "}
    </p>

    <img
      src={final_poster}
      alt="Final Poster 2.671"
      style={{ width: "100%" }}
    />
    <div style={{ display: "flex", justifyContent: "center" }}>
      <iframe
        title="Final Paper"
        frameBorder="0"
        scrolling="no"
        width="640"
        height="825"
        src="https://drive.google.com/file/d/1tuefc14cv3ePiWTb6PyOt_W6kqi-TyDb/preview"
      ></iframe>
    </div>
  </>
)

export default Piano_measurement
