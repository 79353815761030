import React from "react"

import styles from "../styles/project.module.css"
import { Button, Modal, Divider } from "semantic-ui-react"

const ProjectTile = ({ name, picture, link, openModal }) => (
  <div className={styles.projOuter}>
    <div
      className={`${styles.projContainer} ${styles.proj} ${
        link ? styles.projLink : ""
      }`}
      onClick={openModal}
    >
      <div className={`${styles.projtext} ${styles.proj}`}>
        <p>{name}</p>
      </div>
      <div className={`${styles.filter} ${styles.proj}`} />
      <img src={picture} className={`${styles.proj} ${styles.projpic}`} />
    </div>
  </div>
)

const Project = ({ children, ...tileProps }) => {
  const [open, setOpen] = React.useState(false)

  if (children) {
    return (
      <>
        <ProjectTile
          {...tileProps}
          link={true}
          openModal={() => setOpen(true)}
        />
        <Modal
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "2rem 2rem 0rem 2rem",
            }}
          >
            <h1>{tileProps.name}</h1>
            <Button
              onClick={() => setOpen(false)}
              style={{ height: "36px" }}
              icon="close"
            />
          </div>
          <Divider />
          <div style={{ padding: "2rem 2rem" }}>{children}</div>
        </Modal>
      </>
    )
  } else {
    return <ProjectTile {...tileProps} link={false} />
  }
}

export default Project
