import React from "react"

import yoyoPoster from "../images/yoyoposter.jpg"

const Yoyoproject = () => (
  <>
    <p>
      I designed and manufactured 50 yoyos with 5 other students for Design and
      Manufacturing II (2.008). The process involved:
      <ul>
        <li>
          Ideating and designing the yoyo, which had 5 distinct plastic pieces
          in assembly. We used Fusion 360 for CAD and Cam.
        </li>
        <li>
          Milling the distinct molds and reiterating on the tooling process to
          achieve desired parts.
        </li>
        <li>
          Injection molding and thermoforming parts, and performing quality
          control to ensure parts fit together tightly.
        </li>
        <li>
          Manufacturing 50 nearly identical yoyos with streamlined injection
          molding and thermoforming processes.
        </li>
      </ul>
      <br />
      This{" "}
      <a href="http://sushiyoyo.weebly.com" style={{ color: "#0000EE" }}>
        informal blog
      </a>{" "}
      details the progress made each week. The following poster describes the
      process and outcomes in detail.
    </p>

    <img src={yoyoPoster} alt="yoyoPoster" style={{ width: "100%" }} />
  </>
)

export default Yoyoproject
